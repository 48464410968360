import React, { useEffect, useState, useRef } from "react";
import { Layout, ResponsiveImage } from '../../components';
import { Helmet } from "react-helmet";
import { getArtist, formatCCFixed, debounce } from "../../components/shared/Utils";


// markup
const Detail = ({ location }) => {

	const artistWrapperRef = useRef(null);
	const [artist, setArtist] = useState(null);
	const [artistWrapperHeight, setArtistWrapperHeight] = useState('');

	let headerHeight = 0;

	useEffect(() => {

		headerHeight = document.querySelector('header').clientHeight;

		/* let hash = location.hash;
		hash = hash.indexOf('#!/') === 0 ? hash.substr(3) : false; */

		getArtist(artist => {
			setArtist(artist);
			setArtistWrapperHeight(artistWrapperRef.current.clientHeight);

			debouncedElHeightComparison();

		}, location.search.indexOf('?') === 0 ? location.search.substring(1) : '');

	}, []);

	const [windowSize, setWindowSize] = React.useState({
		height: typeof window !== 'undefined' ? window.innerHeight : 0,
		width: typeof window !== 'undefined' ? window.innerWidth : 0,
	});

	React.useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setWindowSize({
				height: window.innerHeight,
				width: window.innerWidth,
			});
			setArtistWrapperHeight(artistWrapperRef.current.clientHeight);
			headerHeight = document.querySelector('header').clientHeight;

			debouncedElHeightComparison();

		}, 300);

		window.addEventListener('resize', debouncedHandleResize);

		return _ => {
			window.removeEventListener('resize', debouncedHandleResize);
		};
	});

	const debouncedElHeightComparison = debounce(function elHeightComparison() {
		if (window.innerHeight > artistWrapperRef.current.clientHeight + headerHeight + 40) {
			artistWrapperRef.current.classList.add('bigger-than');
		} else {
			artistWrapperRef.current.classList.remove('bigger-than');
		};
	}, 300);

	return (
		<Layout location={location}>
			<Helmet>
				<link href="/assets/css/detail.css" rel="stylesheet" type="text/css" />
				{artist != null ? (
					<title>Ephemeral/Ethernal - {artist.name}</title>
				) : null}
			</Helmet>

			<section data-name="layout" id={'artist_detail_page'}>

				{artist == null ? (

					<>
						<div className={'content-placeholder d-flex justify-content-center align-items-center mt-5'}>
							<div className={'gradient py-5 text-center col-12 d-flex justify-content-center align-items-center'} style={{ minHeight: 40 + 'vh' }}>Loading...</div>
						</div>
					</>

				) : (

					<>
						{artist.highlight_square_paths || artist.highlight_horizontal_paths ? (
							<div className={'artist-banner'}>
								<ResponsiveImage
									initialSrc={artist.highlight_square_paths['375']}
									initialSrcSet={`${artist.highlight_square_paths['375']} 1x, ${artist.highlight_square_paths['750']} 2x`}
									alt={"Artist Highlight Image"}
									cssClasses={"optional css-classes as-a-string"}
									sources={[
										/* XXL */
										{
											srcset: [
												{
													src: artist.highlight_horizontal_paths['1400_webp'],
													xd: "1x",
												},
												{
													src: artist.highlight_horizontal_paths['2800_webp'],
													xd: "2x",
												}
											],
											media: "(min-width: 1400px)",
										},
										{
											srcset: [
												{
													src: artist.highlight_horizontal_paths['1400'],
													xd: "1x",
												},
												{
													src: artist.highlight_horizontal_paths['2800'],
													xd: "2x",
												}
											],
											media: "(min-width: 1400px)",
										},
										/* XL */
										{
											srcset: [
												{
													src: artist.highlight_horizontal_paths['1200_webp'],
													xd: "1x",
												},
												{
													src: artist.highlight_horizontal_paths['2400_webp'],
													xd: "2x",
												}
											],
											media: "(min-width: 1200px)",
										},
										{
											srcset: [
												{
													src: artist.highlight_horizontal_paths['1200'],
													xd: "1x",
												},
												{
													src: artist.highlight_horizontal_paths['2400'],
													xd: "2x",
												}
											],
											media: "(min-width: 1200px)",
										},
										/* LG */
										{
											srcset: [
												{
													src: artist.highlight_horizontal_paths['992_webp'],
													xd: "1x",
												},
												{
													src: artist.highlight_horizontal_paths['1984_webp'],
													xd: "2x",
												}
											],
											media: "(min-width: 992px)",
										},
										{
											srcset: [
												{
													src: artist.highlight_horizontal_paths['992'],
													xd: "1x",
												},
												{
													src: artist.highlight_horizontal_paths['1984'],
													xd: "2x",
												}
											],
											media: "(min-width: 992px)",
										},
										/* MD */
										{
											srcset: [
												{
													src: artist.highlight_square_paths['768_webp'],
													xd: "1x",
												},
												{
													src: artist.highlight_square_paths['1536_webp'],
													xd: "2x",
												}
											],
											media: "(min-width: 768px)",
										},
										{
											srcset: [
												{
													src: artist.highlight_square_paths['768'],
													xd: "1x",
												},
												{
													src: artist.highlight_square_paths['1536'],
													xd: "2x",
												}
											],
											media: "(min-width: 768px)",
										},
										/* XS */
										{
											srcset: [
												{
													src: artist.highlight_square_paths['375_webp'],
													xd: "1x",
												},
												{
													src: artist.highlight_square_paths['750_webp'],
													xd: "2x",
												}
											],
										},
									]}
								/>

							</div>
						) : null}

						<div className={'page-container d-lg-flex'}>

							<div className={'container-fluid col-12 col-lg-5 py-5'}>
								<div className={'artist-wrapper bigger-than'} ref={artistWrapperRef} style={{ '--refHeight': artistWrapperHeight + 'px' }}>
									<div className={'d-flex align-items-center mb-4'}>
										{artist.avatar_paths ? (
											<div className={'avatar'}>
												<ResponsiveImage
													initialSrc={artist.avatar_paths['90']}
													initialSrcSet={`${artist.avatar_paths['90']} 1x, ${artist.avatar_paths['180']} 2x`}
													alt={artist.name}
													cssClasses={"optional css-classes as-a-string"}
													sources={[
														{
															srcset: [
																{
																	src: artist.avatar_paths['90_webp'],
																	xd: "1x",
																}
															],
														},
														{
															srcset: [
																{
																	src: artist.avatar_paths['180_webp'],
																	xd: "2x",
																}
															],
														}
													]}
												/>
											</div>
										) : (null)}
										<div className={'artist-name'}>{artist.name}</div>
									</div>

									{artist.bio != null ? (
										<div className={'artist-description offset-1'}>{artist.bio}</div>
									) : null}

									{artist.social_networks ? ( /** TODO loop */
										<div className={'d-flex justify-content-center justify-content-lg-start my-5 my-lg-4 offset-lg-1'}>
											<ul className={'w-100 d-flex justify-content-center align-items-center justify-content-lg-start'}>
												{artist.social_networks.map((sn) => {
													return (
														<li className="d-inline-block social-networks">
															<a href={(sn.sn_url.indexOf('https://') !== -1 ? '' : 'https://') + sn.sn_url} target="_blank" rel="noopener">
																<img src={"/assets/img/icons/social_networks/" + sn.sn_type + "_icon.svg"} alt={sn.sn_type} />
															</a>
														</li>
													)
												})}
											</ul>
										</div>
									) : null}

									{artist.more_about_url ? (
										<div className={'more-about d-flex justify-content-center justify-content-lg-start my-5 my-lg-4 offset-lg-1'}>
											<a href={(artist.more_about_url.indexOf('https://') !== -1 ? '' : 'https://') + artist.more_about_url} rel="noopener" target="_blank">More about the artist</a>
										</div>
									) : null}

									{artist.more_artworks_url ? (
										<div className={'load-more my-5 justify-content-center justify-content-lg-start my-5 my-lg-4 d-none d-lg-flex offset-lg-1'}>
											<a href={(artist.more_artworks_url.indexOf('https://') !== -1 ? '' : 'https://') + artist.more_artworks_url} rel="noopener" target="_blank">More artworks from the artist</a>
										</div>
									) : null}

									{artist.name === "Vhils" ?
										<div className={'load-more my-5 justify-content-center justify-content-lg-start my-5 my-lg-4 d-none d-lg-flex offset-lg-1'}>
											<a href={'https://drp.io/drops/vhils'} rel="noopener" target="_blank">Check out the artist's Layers NFT project</a>
										</div>
										: null}

								</div>
							</div>



							{artist.nft_assets ? (
								<div className={'container-fluid d-flex align-items-center flex-column ps-lg-0'}>

									<div className={'artworks-container my-lg-4'}>

										<h1 className={'artworks-title col-12 col-md-6 col-xl-4 my-5 my-md-2 d-lg-none'}>Art<br className={'line-break'} />wor<br className={'line-break'} />ks</h1>


										{/***** ARTWORKS *****/}

										{/***** CARD *****/}
										{artist.nft_assets.map((asset) => {
											return (
												<div className={'card col-12 col-md-6 my-5 my-md-2'}>
													<div className={'artwork-img'}>
														<a href={asset.permalink} rel="noreferrer" target="_blank" >
															<div className={'open-hover-fx'}>Open</div>
															<ResponsiveImage
																initialSrc={asset.image_preview_url}
																initialSrcSet={`${asset.image_preview_url} 1x, ${asset.image_url} 2x`}
																alt={asset.short_name}
															/>
														</a>
													</div>

													<div className={'artwork-title'}>{asset.short_name}</div>
													<div className={'artwork-description'}>{asset.description}</div>
													<div className={'artwork-artist'}>
														<div className={'avatar'}>
															{artist.avatar_paths ? (
																<ResponsiveImage
																	initialSrc={artist.avatar_paths['24']}
																	initialSrcSet={`${artist.avatar_paths['24']} 1x,${artist.avatar_paths['48']} 2x`}
																	alt="Artist photo"
																	sources={[
																		{
																			srcset: [
																				{
																					src: artist.avatar_paths['24_webp'],
																					xd: "1x",
																				},
																				{
																					src: artist.avatar_paths['48_webp'],
																					xd: "2x",
																				}
																			],
																		},
																		{
																			srcset: [
																				{
																					src: artist.avatar_paths['24'],
																					xd: "1x",
																				},
																				{
																					src: artist.avatar_paths['48'],
																					xd: "2x",
																				}
																			],
																		}
																	]}
																/>
															) : (
																artist.name.substr(0, 1)
															)}
														</div>
														<div className={'name'}>{artist.name}</div>
													</div>
													<div className={'artwork-auction'}>
														{asset.last_price ? (<div className={'bid-start'}>last price</div>) : null}
														<div className={'bid'}>
															<div>
																{asset.last_price ? (
																	<>
																		<div className={'fixed-with-gradient'}>{formatCCFixed(asset.last_price, asset.last_payment_token.decimals, 4).replace('.', ',')}
																		</div>
																		<div>{asset.last_payment_token.symbol}</div>
																	</>
																) : (
																	<a href={asset.permalink} rel="noopener" target="_blank" className={'not-on-sale'}>make an offer</a>
																)}
															</div>
															<a href={asset.permalink} rel="noopener" target="_blank" className={'btn btn--secondary'}>OpenSea</a>
														</div>
													</div>
												</div>
											)
										})}

										{/***** ARTWORKS *****/}


									</div>

									<div className={'load-more my-5 d-flex justify-content-center my-5 d-lg-none'}>
										<a href="https://www.google.com/" rel="noopener" target="_blank">More artworks from the artist</a>
									</div>
								</div>
							) : null}

						</div>
					</>

				)}

			</section>

		</Layout>
	)
}

export default Detail
